import React from 'react';
import firebase from 'firebase/app';
import Popup from 'reactjs-popup'
import 'firebase/auth';
import Loader from './loader';
import SignUpForm from './signUpForm';
import WelcomeScreen from './welcomeScreen';
import Link from './link';
import { initializeBackendTokens, clearTokens } from '../lib/backendTokenHelper';
import {addUser, getUser, getDetailsByToken, checkUserExists, acceptInvitation} from '../lib/userDataHelper';
import {
  signUpUsingGoogle,
  signUpUsingFacebook,
  signUpUsingEmail,
  signUpUsingXero,
  signUpUsingXeroAdvisory
} from '../lib/authHelper';
import {clearAllAcquisitionData, getAcquisitionData} from '../lib/acquisitionHelper';
import { loadIntercom } from '../lib/intercom'
import './signUpWrapper.css';
import {getParameterByName, removeURLParameter} from "../lib/urlHelper";

class SignUpWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      firebaseInitialized: false,
      redirected: false,
      emailFormPrepopulated: false,
      emailFormFields: {
        firstName: '',
        lastName: '',
        email: '',
        password: ''
      },
      hasEmailFieldsData: false,
      popupOpen: this.props.appConfig.popupOpen,
      signUpFormTitle: this.props.appConfig.signUpFormTitle,
      cat: null, // custom auth token
      partnerCustomerTokenInRedirectURL: null,

      // invite details
      invitation: null,
      business: null,
      product:null, //product AIP or SMB app to determine sign up component
    };
  }

  componentDidMount () {
    if (this.props.appConfig.popupEnabled) {
      // Register external trigger for popup opener
      window.aiderWebApp = window.aiderWebApp || {};
      window.aiderWebApp.openPopup = this.openPopup;
    }
  }

  componentDidUpdate () {
    if (!this.state.emailFormPrepopulated) {
      this.prepopulateEmailForm();
    } else if (!this.state.firebaseInitialized) {
      if (!firebase.apps.length) {
        firebase.initializeApp(this.props.config.firebase);
      }
      const product=getParameterByName('product',window.location.href);
      console.log('PRODUCT:',product);
      const cat = getParameterByName("cat", window.location.href);
      const partnerCustomerTokenInRedirectURL = getParameterByName("token", window.location.href);

      this.setState({ firebaseInitialized: true, cat, partnerCustomerTokenInRedirectURL,product }, () => {

        if (cat && partnerCustomerTokenInRedirectURL) {

          let url = removeURLParameter(window.location.href, "cat");
          url = removeURLParameter(url, "token");
          window.history.replaceState(null, document.title, url);

          firebase.auth().signInWithCustomToken(cat)
              .then(authResult => this.authResult = authResult)
              .then(() => {
                this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(this.onAuthStateChanged)
              })
              .catch(error => {
                const signInError = "Failed to sign in. Error: " + error.code;
                this.setState({ loading: false, signInError });
              });
        } else {
          firebase.auth().getRedirectResult()
              .then(authResult => this.authResult = authResult)
              .then(() => {
                this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(this.onAuthStateChanged)
              })
        }
      });
    }
  }

  componentWillUnmount () {
    if (this.unregisterAuthObserver) {
      this.unregisterAuthObserver();
    }
  }

  openPopup = () => {
    this.setState({ popupOpen: true });
  };

  closePopup = () => {
    this.setState({ popupOpen: false });
  };

  getPartnerErrorMessage = errorMetaData => {
    let message = <>Your {errorMetaData.partnerName} link is invalid or has expired. You can still sign up with Aider but your {errorMetaData.partnerName} account won't be connected.</>;
    let contact = <>Please contact {errorMetaData.partnerName} for more details.</>;
    if (errorMetaData.ospKey === 'paymark') {
      contact = <>Please email <a href="mailto:support@paymark.co.nz">support@paymark.co.nz</a> or call <strong>0800 729 627</strong> for more details.</>;
    }

    return <>{message}&nbsp;{contact}</>;
  }

  updateEmailFormFields = (firstName, lastName, emailAddress, signUpFormTitle, invitation, business) => {
    this.setState(prevState => {
      const emailFormFields = prevState.emailFormFields;
      emailFormFields.firstName = firstName;
      emailFormFields.lastName = lastName;
      emailFormFields.email = emailAddress;
      return { emailFormFields, emailFormPrepopulated: true, hasEmailFieldsData: true, signUpFormTitle, invitation, business };
    });
  }

  prepopulateEmailForm = () => {
    getDetailsByToken(this.props.apiLinks)
      .then(details => {
        if (details) {
          const { partnerCustomer, invitation, business } = details;
          if (partnerCustomer && partnerCustomer.partnerData) {
            this.updateEmailFormFields(
              partnerCustomer.partnerData.firstName,
              partnerCustomer.partnerData.lastName,
              partnerCustomer.partnerData.emailAddress,
              this.state.signUpFormTitle
            );
          } else if (invitation && business) {
            this.isInvitation = true;
            const signUpFormTitle = <div>You have been invited to join {business.name}'s<br/>Aider Advisory Intelligence Platform</div>;
            this.updateEmailFormFields(
              invitation.givenName,
              invitation.familyName,
              invitation.email,
              signUpFormTitle,
              invitation,
              business
            );
          }
        } else {
          this.setState({ emailFormPrepopulated: true });
        }
      })
      .catch(error => {
        let signInError = this.props.signInError;
        if (error.metaData && error.metaData.partnerName) {
          signInError = this.getPartnerErrorMessage(error.metaData);
        }

        clearAllAcquisitionData();
        this.setState({ emailFormPrepopulated: true, signInError });
      });
  }

  loadIntercom = () => loadIntercom(this.props.config, this.props.apiLinks)
    .catch(error => {
      console.error('Error loading intercom ' + error)
    });

  fetchUserData = async (user) => {
    const userExists = await checkUserExists(this.props.apiLinks, user.uid);
    if (!userExists) {

      // console.log("getting invitation and business: ", this.state.invitation, this.state.business);
      await addUser(this.authResult, this.props.apiLinks, this.isInvitation, this.state.partnerCustomerTokenInRedirectURL);

      if (this.isInvitation && this.state.partnerCustomerTokenInRedirectURL) {
        const idToken = await user.getIdToken();
        await initializeBackendTokens(this.props.apiLinks, idToken);
        const { inviteToken } = getAcquisitionData();
        const acceptInvResponse = await acceptInvitation(this.props.apiLinks, inviteToken);
      }

      this.onSignUpSucceeded();
    } else {
      // console.log("aider user exists, isInvitation: ", this.isInvitation);
      if (this.isInvitation) {

        const idToken = await user.getIdToken();
        await initializeBackendTokens(this.props.apiLinks, idToken);

        const { inviteToken } = getAcquisitionData();

        const acceptInvResponse = await acceptInvitation(this.props.apiLinks, inviteToken);
        // console.log("acceptInvitation response: ", acceptInvResponse);
        return getUser(this.props.apiLinks, user.uid);
      }
    }

    const idToken = await user.getIdToken();
    await initializeBackendTokens(this.props.apiLinks, idToken);
    return getUser(this.props.apiLinks, user.uid);
  }

  onAuthStateChanged = (user) => {
    this.setState((prevState) => ({ loading: true, popupOpen: prevState.popupOpen || !!user }), () => {
      if (user) {
        this.fetchUserData(user)
          .then(userDetails => {
            clearAllAcquisitionData();
            this.setState({ loading: false, user: userDetails.user }, this.loadIntercom);
          })
          .catch(this.handleSignInError);
          // .then(clearAllAcquisitionData);
      } else {
        // String.prototype.endsWith is not available in some old browsers
        function endsWith(str, suffix) {
          return str.indexOf(suffix, str.length - suffix.length) !== -1;
        }

        if (endsWith(window.location.pathname,"/xero-ssu")
            || endsWith(window.location.pathname,"/xero-ssu/")) {
          signUpUsingXero(this.props.apiLinks)
              .catch(error => {
                let formError = error;
                if (typeof error !== 'string') {
                  formError = error.message;
                }
                this.setState({ loading: false, signInError: formError });
              });
        }
        else if(endsWith(window.location.pathname,"/xero-ssu-advisory")
            || endsWith(window.location.pathname,"/xero-ssu-advisory/")) {
          signUpUsingXeroAdvisory(this.props.apiLinks)
              .catch(error => {
                let formError = error;
                if (typeof error !== 'string') {
                  formError = error.message;
                }
                this.setState({loading: false, signInError: formError});
              });
        }

        else {
          this.setState({ loading: false, user: null })
        }
      }
    });
  }

  onSignUpFailed () {
    if (this.props.appConfig.onSignUpFailed) {
      this.props.appConfig.onSignUpFailed();
    }
  }

  onSignUpSucceeded () {
    if (this.props.appConfig.onSignUpSucceeded) {
      this.props.appConfig.onSignUpSucceeded();
    }
  }

  handleSignInError = (error) => {
    let signInError = this.props.signInError;

    if (error.code === 'INSUFFICIENT_PERMISSIONS' && error.message === "Invitation email address doesn't match sign-up email address") {
      signInError = <p>Invitation email address doesn't match sign-up email address. Please try again or <Link href="mailto:info@aider.ai">contact us</Link> for assistance.</p>

      this.onSignUpFailed();
      this.setState({ loading: false, signInError }, this.signOutWithoutClearingAcquisitionData);
    } else {
      if (error.code === 'INVALID_PARTNER_TOKEN' && error.metaData && error.metaData.partnerName) {
        signInError = this.getPartnerErrorMessage(error.metaData.partnerName);
      }
      if (error.code === 'INVALID_DATA' && error.message === 'User already exists') {
        signInError = <p>There was an error creating your account; the provided email address is already in use. Either sign up using a different email address, or <Link href="mailto:info@aider.ai">contact us</Link> for assistance.</p>
      }
      if (error.code === 'NOT_FOUND' && error.message === 'No pending invitation found for provided invitation token') {
        signInError = <p>The invitation has been accepted or expired already.</p>
        this.isInvitation = false;
      }
      this.onSignUpFailed();
      this.setState({ loading: false, signInError }, this.signOut);
    }
  }

  signOutWithoutClearingAcquisitionData = () => {
    clearTokens();
    return firebase.auth().signOut();
  }

  signOut = () => {
    clearTokens();
    clearAllAcquisitionData()
    return firebase.auth().signOut();
  }

  signOutAndReloadPage = () => {
    this.signOut().finally(() => {
      window.location.reload();
    });
  }

  handleEmailFormChange = (event) => {
    const { name, value } = event.target;
    this.setState(prevState => {
      const emailFormFields = prevState.emailFormFields;
      emailFormFields[name] = value;
      return { emailFormFields };
    });
  }

  signUpUsingEmail = (fields) => {
    this.setState({ loading: true }, () => {
      signUpUsingEmail(firebase, fields)
        .then(authResult => {
          this.authResult = authResult;
          this.authResult.emailSignUp = fields;
        })
        .catch(error => {
          let formError = error;
          if (typeof error !== 'string') {
            formError = error.message;
          }
          this.setState({ loading: false, signInError: formError });
        });
    });
  }

  renderContent = () => {
    return (
      <div className={`${this.state.product==='aip'?'AIPHalfWidth':''} sign-up`}>
        <Loader isLoading={this.state.loading}>
          {this.state.signInError && <div className="sign-up-error">{this.state.signInError}</div>}
          {this.state.user && <WelcomeScreen product={this.state.product} firebase={firebase} signOutFunc={this.signOutAndReloadPage} {...this.state.user} />}
          {
            !this.state.user
            && this.state.firebaseInitialized
            && <SignUpForm
                product={this.state.product}
              title={this.state.signUpFormTitle}
              signUpTypes={this.props.appConfig.signUpTypes}
              readOnly={this.state.hasEmailFieldsData}
              signUpUsingGoogle={() => signUpUsingGoogle(firebase)}
              signUpUsingFacebook={() => signUpUsingFacebook(firebase)}
              signUpUsingXero={() => signUpUsingXero(this.props.apiLinks)}
              signUpUsingEmail={this.signUpUsingEmail}
              handleEmailFormChange={this.handleEmailFormChange}
              emailFormFields={this.state.emailFormFields}
            />
          }
        </Loader>
      </div>
    );
  }

  render () {
    if (this.props.appConfig.popupEnabled) {
      return (
        <Popup
          open={this.state.popupOpen}
          closeOnDocumentClick
          onClose={this.closePopup}
          contentStyle={{ maxWidth: '500px', border: 'none', width: '100%' }}
          overlayStyle={{ background: 'rgba(0, 0, 0, 0.7)', zIndex: '10000' }}
        >
          <div>
            <button className="sign-up-popup-close" onClick={this.closePopup}>&times;</button>
            {this.renderContent()}
          </div>
        </Popup>
      );
    }

    return this.renderContent();
  }
}

SignUpWrapper.defaultProps = {
  signInError: <p>There was an error creating your account. Please <Link href="mailto:info@aider.ai">contact us</Link> for more information.</p>
};

export default SignUpWrapper;
