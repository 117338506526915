import jwt from 'jsonwebtoken';
import { makeGetRequest } from './apiHelper';
import { getAcquisitionData } from './acquisitionHelper';
import { getBackendTokens, refreshTokens } from './backendTokenHelper';

const getIntercomVerification = async apiLinks => {
  const backendTokens = await refreshTokens(apiLinks);
  const headers = {
    Authorization: `Bearer ${backendTokens.access_token}`
  };
  const endpoint = apiLinks.intercomVerification.href.replace('{type}', 'web');
  return makeGetRequest(endpoint, headers);
}

export const loadIntercom = async (config, apiLinks) => {
  const intercomVerification = await getIntercomVerification(apiLinks);
  const appId = config.osp.intercom.appId;

  let utm_source;
  let utm_medium;
  let utm_campaign;
  const { acquisition } = getAcquisitionData();
  if (acquisition) {
    utm_source = acquisition.utmSource;
    utm_medium = acquisition.utmMedium;
    utm_campaign = acquisition.utmCampaign;
  }

  const backendTokens = getBackendTokens();
  const decodedIdToken = jwt.decode(backendTokens.id_token);
  let company;
  if (decodedIdToken.claims.businesses && decodedIdToken.claims.businesses.length > 0) {
    const business = decodedIdToken.claims.businesses[0];

    company = {
      id: business.id,
      name: business.name,
      website: business.website,
      created_at: business.createdAt,
      area: business.area,
      country_code: business.countryCode,
      time_zone_id: business.timeZoneId,
    };
  }

  window.intercomSettings = {
    app_id: appId,

    // Standard data
    user_id: decodedIdToken.sub,
    user_hash: intercomVerification._embedded.userVerification.hmac,
    name: decodedIdToken.claims.displayName,
    email: decodedIdToken.claims.email,
    phone: decodedIdToken.claims.phoneNumber,
    created_at: decodedIdToken.claims.createdAt,
    photo_url: decodedIdToken.claims.photoUrl,
    company,

    // Custom data
    email_verified: decodedIdToken.claims.emailVerified
  };

  if (utm_source) {
    window.intercomSettings.utm_source = utm_source;
  }

  if (utm_medium) {
    window.intercomSettings.utm_source = utm_medium;
  }

  if (utm_campaign) {
    window.intercomSettings.utm_source = utm_campaign;
  }

  // Provided by Intercom, removed event listener
  (function(){
    var w=window;
    var ic=w.Intercom;
    if(typeof ic==="function"){
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    }else{
      var d=document;
      var i=function(){i.c(arguments)};
      i.q=[];
      i.c=function(args){i.q.push(args)};
      w.Intercom=i;
      var s=d.createElement('script');
      s.type='text/javascript';
      s.async=true;
      s.src='https://widget.intercom.io/widget/' + appId;
      var x=d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s,x);
    }
  })();
};
