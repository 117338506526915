import React, { Component } from 'react';
import SignUpWrapper from './components/signUpWrapper';
import withConfig from './wrappers/withConfig';
import logo from './logo.svg';
import './app.css';

class App extends Component {
  render () {
    return (
      <div className="app">
        {this.props.appConfig.showLogo && <header className="app-header">
          <img src={logo} className="app-logo" alt="logo" />
        </header>}
        <section className="app-body">
          <SignUpWrapper {...this.props} />
        </section>
      </div>
    );
  }
}

App.defaultProps = {
  appConfig: {}
};

export default withConfig(App);
