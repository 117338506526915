import React from 'react';
import { makeGetRequest } from '../lib/apiHelper';
import { getAcquisitionData } from '../lib/acquisitionHelper';

const withConfig = (WrappedComponent) => {
  class ComponentWithConfig extends React.Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    async componentDidMount () {
      try {
        // Initialize acquisition data cookies
        getAcquisitionData();

        // Initialize root links and runtime config
        const rootResponse = await makeGetRequest(process.env.REACT_APP_ROOT_ENDPOINT);
        const apiLinks = rootResponse._links;
        const configResponse = await makeGetRequest(apiLinks.config.href);
        const config = configResponse._embedded.configuration;
        this.setState({ apiLinks, config });
      } catch (error) {
        this.setState({ failed: true });
      }
    }

    render () {
      if (this.state.failed) {
        return <div>{this.props.failureMessage}</div>
      }

      return <WrappedComponent apiLinks={this.state.apiLinks} config={this.state.config} {...this.props} />;
    }
  }

  ComponentWithConfig.defaultProps = {
    failureMessage: 'There was an error loading this page. Please try again later.'
  };

  return ComponentWithConfig;
};

export default withConfig;
