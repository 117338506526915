import React from 'react';
import './separator.css';

const Link = props => {
  const newWindow = props.newWindow ? {
    target: '_blank',
    rel: 'noopener noreferrer'
  } : {};

  return (
    <a style={{color:'#4264ea',textDecoration:'none'}} className="link" href={props.href} {...newWindow}>
      {props.children}
    </a>
  )
};

export default Link;
