import React from 'react';
import './loader.css';

const Loader = props => (
  <>
    {
      props.isLoading
        ? <div className="loader"></div>
        : <>{props.children}</>
    }
  </>
);

export default Loader;
