// Polyfill for fetch
import 'isomorphic-fetch';

const jsonReponse = response => response.text().then(text => text ? JSON.parse(text) : {});

const makeApiRequest = (url, method, headers = {}, body) => {
  const request = {
    method,
    headers: {
      'X-API-Key': process.env.REACT_APP_API_KEY,
      ...headers
    }
  };

  if (body) {
    request.body = body
  }

  return fetch(url, request)
    .then(async response => {
      const jsonData = await jsonReponse(response);
      if (response.ok) {
        return jsonData;
      }

      let error = {};
      if (jsonData._embedded && jsonData._embedded.error) {
        error = jsonData._embedded.error;
      } else {
        error = jsonData;
      }
      return Promise.reject(error);
    });
}
const makeAdvisoryApiRequest = (url, method, headers = {}, body) => {
  const request = {
    method,
    headers: {
      'X-API-Key': process.env.REACT_APP_API_KEY_ADVISORY,
      ...headers
    }
  };

  if (body) {
    request.body = body
  }

  return fetch(url, request)
    .then(async response => {
      const jsonData = await jsonReponse(response);
      if (response.ok) {
        return jsonData;
      }

      let error = {};
      if (jsonData._embedded && jsonData._embedded.error) {
        error = jsonData._embedded.error;
      } else {
        error = jsonData;
      }
      return Promise.reject(error);
    });
}

export const makeGetRequest = (url, headers) => makeApiRequest(url, 'GET', headers);
export const makeHeadRequest = (url, headers) => makeApiRequest(url, 'HEAD', headers);
export const makePostRequest = (url, headers, body) => makeApiRequest(url, 'POST', headers, body);
export const makeAdvisoryPostRequest = (url, headers, body) => makeAdvisoryApiRequest(url, 'POST', headers, body);
