import { isValidPhoneNumber } from 'react-phone-number-input';
import {makeAdvisoryPostRequest, makeGetRequest, makePostRequest} from "./apiHelper";
import cookie from 'js-cookie';


export const signUpUsingGoogle = firebase => {
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({ prompt: 'select_account' });
  firebase.auth().signInWithRedirect(provider);
};

export const signUpUsingFacebook = firebase => {
  var provider = new firebase.auth.FacebookAuthProvider();
  provider.setCustomParameters({ auth_type: 'https' });
  firebase.auth().signInWithRedirect(provider);
};

export const signUpUsingXero = async apiLinks => {
  const redirectUrl = process.env.REACT_APP_SSO_REDIRECT_URL;
  const url = apiLinks.singleSignOn.href.replace('{ospKey}', 'xero').replace('{appKey}', 'accounting') + "?redirectUrl=" + encodeURIComponent(redirectUrl);
  const response = await makePostRequest(url);
  const resultUrl = response._embedded.sso._links.redirect.href;
  window.location.href = resultUrl;
};
export const signUpUsingXeroAdvisory = async apiLinks => {
  cookie.set('source','xssu');
  const redirectUrl = process.env.REACT_APP_ADVISORY_URL;
  const url = apiLinks.singleSignOn.href.replace('{ospKey}', 'xero').replace('{appKey}', 'accounting') + "?redirectUrl=" + encodeURIComponent(redirectUrl);
  const response = await makeAdvisoryPostRequest(url);
  const resultUrl = response._embedded.sso._links.redirect.href;
  window.location.href = resultUrl;
};

export const signUpUsingEmail = (firebase, fields = {}) => {
  const { firstName, email, password, phoneNumber } = fields;

  if (!firstName || !email || !password) {
    return Promise.reject('First name, email and password fields are required');
  }

  if (phoneNumber && !isValidPhoneNumber(phoneNumber)) {
    return Promise.reject('Invalid phone number');
  }

  return firebase.auth().createUserWithEmailAndPassword(email, password).catch(error => catchCreateUser(firebase, fields, error));
};

const catchCreateUser = (firebase, fields, error) => {
  switch (error.code) {
    case 'auth/email-already-in-use':
      const { email, password } = fields;
      return firebase.auth().signInWithEmailAndPassword(email, password)
        .catch(catchSignInUser);
    case 'auth/weak-password':
      return Promise.reject('Weak password');
    case 'auth/invalid-email':
      return Promise.reject('Invalid email');
    case 'auth/operation-not-allowed':
      return Promise.reject('Oops, email sign up not enabled');
    default:
      return Promise.reject(error);
  }
}

const catchSignInUser = error => {
  switch (error.code) {
    case 'auth/user-disabled':
      return Promise.reject('Oops, your account has been disabled');
    case 'auth/invalid-email':
    case 'auth/user-not-found':
    case 'auth/wrong-password':
      return Promise.reject('Invalid email or password');
    // case 'auth/invalid-custom-token':
    //   return Promise.reject('Server error - invalid custom token');
    default:
      return Promise.reject(error);
  }
};
