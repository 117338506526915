import React from 'react';
import Separator from './separator';
import Link from './link';

class WelcomeScreen extends React.Component {

  getUserName = () => {
    let name = this.props.displayName;
    if (!name) {
      name = [this.props.givenName, this.props.familyName].filter(name => name).join(' ').trim();
    }

    return name;
  }

  getMessage = (product) => {
    if (this.props.partnerData && this.props.partnerData.ospKey === 'paymark') {
      return 'Your Aider account is now connected with Paymark Insights, download the app and login to use it.';
    }

    return `Get started with the Aider ${product==='aip'?'Advisory':''} Platform`;
  };

  render () {
    return (
      <div className="user-details">
        <h2 className="heading welcome-screen-heading">Welcome {this.getUserName()}!</h2>

        <p>{this.getMessage(this.props.product)}</p>
          {this.props.product === 'app' ?
              <div>
                  <Link href={this.props.appStoreLink} newWindow>
                      <img src={`${process.env.PUBLIC_URL}/app-store-badge.png`} alt="Download  on the App Store"
                           className="app-link-img"/>
                  </Link>
                  <Link href={this.props.googlePlayLink} newWindow>
                      <img src={`${process.env.PUBLIC_URL}/google-play-badge.png`} alt="Get it on Google Play"
                           className="app-link-img"/>
                  </Link>
              </div> :

              <>
                  <Link href={process.env.REACT_APP_ADVISORY_URL} newWindow>
                      <button className="button form-submit-btn" onClick={this.signUpUsingEmail}>Take me to the
                          Aider Advisory Intelligence Platform
                      </button>
                  </Link>

                  <Separator/>
              </>
          }

        <section>Not you? <button onClick={this.props.signOutFunc} className="link">click here</button> to sign out.</section>
      </div>
    );
  }
}

WelcomeScreen.defaultProps = {
  appStoreLink: 'https://itunes.apple.com/us/app/aider/id1368956945?ls=1&mt=8',
  googlePlayLink: 'https://play.google.com/store/apps/details?id=ai.aider.Aider'
};

export default WelcomeScreen;
