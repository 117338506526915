import React from 'react';
import PhoneInput from 'react-phone-number-input'
import Separator from './separator';
import SignUpType from '../lib/signUpType';
import Link from './link';
import 'react-phone-number-input/style.css'
import './signUpForm.css';
import whiteLogo from './Aider_Logo_PRIMARY.png';
import aider_welcome_image_g from './aider_welcome_g.gif';

class SignUpForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      redText: false,
    }
  }


  hasSocial = () => (this.props.signUpTypes.includes(SignUpType.GOOGLE) || this.props.signUpTypes.includes(SignUpType.FACEBOOK))

  hasEmail = () => this.props.signUpTypes.includes(SignUpType.EMAIL)

  hasPhone = () => this.props.signUpTypes.includes(SignUpType.WITH_PHONE)

  handlePhoneChange = phoneNumber => this.props.handleEmailFormChange({
    target: {
      name: 'phoneNumber',
      value: phoneNumber
    }
  })

  signUpUsingGoogle(){
    console.log(this.state,'????');
    if(!this.state.checked) {
      return this.setState({redText: true})
    }
    else{
      return this.props.signUpUsingGoogle()
    }
  }

  signUpUsingEmail(){
    if(!this.state.checked) {
      return this.setState({redText: true})
    }
    else{
      this.props.signUpUsingEmail(this.props.emailFormFields);

    }
  }
  signUpUsingXero(){
    if(!this.state.checked) {
      return this.setState({redText: true})
    }
    else{
      return this.props.signUpUsingXero()

    }
  }

  render () {
    const textProps = this.props.readOnly ? { readOnly: true, disabled: true } : {};

    return (
      <div className="sign-up-form" style={{marginTop:100}}>
        <div style={{display:this.props.product==='aip'?'flex':'inline-flex',justifyContent:this.props.product==='aip'?'center':'space-between',flexDirection:'row',flexWrap:'wrap'}}>
          <div style={{width:this.props.product==='aip'?500:'95vw',height:this.props.product==='aip'?600:'95vh'}}>
            <div style={{position:'relative'}}><img style={{position:'absolute',left:this.props.product==='aip'?20:'33%',top:-40}} src={whiteLogo} width={124} height={35} /></div>
            <div style={{maxWidth:this.props.product==='aip'?415:320,whiteSpace:'wrap'}}>{this.props.title && <h3 className={`sign-up-form-title ${this.props.product==='aip'?'AIPTitle':'MobileTitle'}`} >{this.props.title}</h3>}</div>
            <form style={{marginBottom:10,width:this.props.product==='app'?260:500,fontSize:14,display:'inline-block',border:'1px solid #d1d8de',borderRadius:4,padding:8,marginTop:this.props.product==='aip'?115:18}} onClick={(e) => { e.stopPropagation(); console.log('?', e); }}>
              <label style={{paddingTop:8,whiteSpace:'nowrap',fontSize:12,color:!this.state.redText?'#9e9e9e':'red',fontWeight:!this.state.redText?100:600,textAlign:'initial'}} className="Ccontainer">

                I have read and I agree to the <Link href="https://www.aider.ai/terms" newWindow>
                terms</Link> {this.props.product==='app' &&<br />}and the <Link href="https://www.aider.ai/privacy-policy/" newWindow>privacy policy</Link>
                <input type="checkbox" name="Check" checked={this.state.checked} onClick={(e) => { this.setState({ checked:!this.state.checked,redText:false}); }} />
                <span className="checkmark" />
              </label>
            </form>
            <div className='alert-banner'>
              <div className='alert-banner-icon'>
                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 24.0601C18.3513 24.0601 23.5 18.9113 23.5 12.5601C23.5 6.20878 18.3513 1.06006 12 1.06006C5.64873 1.06006 0.5 6.20878 0.5 12.5601C0.5 18.9113 5.64873 24.0601 12 24.0601Z" fill="#4263EA"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9941 19.0601C12.8226 19.0601 13.4941 18.3885 13.4941 17.5601V12.5601C13.4941 11.7316 12.8226 11.0601 11.9941 11.0601C11.1657 11.0601 10.4941 11.7316 10.4941 12.5601V17.5601C10.4941 18.3885 11.1657 19.0601 11.9941 19.0601Z" fill="white"/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4941 7.56006C10.4941 8.38849 11.1657 9.06006 11.9941 9.06006H12.0066C12.8351 9.06006 13.5066 8.38849 13.5066 7.56006C13.5066 6.73163 12.8351 6.06006 12.0066 6.06006H11.9941C11.1657 6.06006 10.4941 6.73163 10.4941 7.56006Z" fill="white"/>
                </svg>
              </div>
              <div className='alert-banner-text'>
                <h2>
                  Please sign up with the same email address as your invite.
                </h2>
                <p>
                  If you have multiple Xero/Google logins, use the one with the SAME email address as your invite.
                </p>
              </div>
            </div>
            <Separator> Sign up with </Separator>
            {
              this.props.product==='app' && <>
                <div className="email-form">
                  <input
                      type="text"
                      name="firstName"
                      value={this.props.emailFormFields.firstName}
                      placeholder="First name"
                      className="text-field first-name"
                      onChange={this.props.handleEmailFormChange}
                      {...textProps}
                  />

                  <input
                      type="text"
                      name="lastName"
                      value={this.props.emailFormFields.lastName}
                      placeholder="Last name"
                      className="text-field last-name"
                      onChange={this.props.handleEmailFormChange}
                      {...textProps}
                  />

                  <input
                      type="text"
                      name="email"
                      value={this.props.emailFormFields.email}
                      placeholder="Email"
                      className="text-field email-address"
                      onChange={this.props.handleEmailFormChange}
                      {...textProps}
                  />

                  <input
                      type="password"
                      name="password"
                      value={this.props.emailFormFields.password}
                      placeholder="Password"
                      className="text-field"
                      onChange={this.props.handleEmailFormChange}
                  />

                  {
                    this.hasPhone() &&  <input
                        type="text"
                        className="text-field phone-number"
                        placeholder="Enter phone number"
                        value={this.props.emailFormFields.phoneNumber}
                        onChange={this.props.handleEmailFormChange}
                    />

                  }
                </div>
                <br />
                <button className="button form-submit-btn" onClick={()=>this.signUpUsingEmail()}>Sign Up</button>
                <br />
                <Separator> Or sign up with </Separator>
              </>
            }

        {

          this.hasSocial() && <>
            <div style={{display:this.props.product==='aip'?'flex':'block',justifyContent:'center'}}>
              <div >
              <button
                className="sign-up-button2 google-sign-up"
                onClick={()=>{this.signUpUsingGoogle()}}
              >
                <img className="social-sign-up-icon" src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" alt="Sign up with Google" />
                <span className="social-sign-up-text">Google</span>
              </button>
              </div>
              <div>

              <button
                  className="sign-up-button2 xero-sign-up"
                  onClick={()=>{this.signUpUsingXero()}}
              >
                <img className="social-sign-up-icon xero-sign-up-icon"
                     src="data:image/svg+xml,%3Csvg viewBox='0 0 45 46' xmlns='http://www.w3.org/2000/svg'%3E %3Ctitle%3EXero%3C/title%3E %3Cpath fill='%2313B5EA' d='M22.457 45.49c12.402 0 22.456-10.072 22.456-22.495C44.913 10.57 34.86.5 22.457.5 10.054.5 0 10.57 0 22.995 0 35.418 10.054 45.49 22.457 45.49' /%3E %3Cpath fill='%23FFFFFF' d='M10.75 22.935l3.832-3.85a.688.688 0 0 0-.977-.965l-3.83 3.833-3.845-3.84a.687.687 0 0 0-.966.979l3.832 3.837-3.83 3.84a.688.688 0 1 0 .964.981l3.84-3.842 3.825 3.827a.685.685 0 0 0 1.184-.473.68.68 0 0 0-.2-.485l-3.83-3.846m22.782.003c0 .69.56 1.25 1.25 1.25a1.25 1.25 0 0 0-.001-2.5c-.687 0-1.246.56-1.246 1.25m-2.368 0c0-1.995 1.62-3.62 3.614-3.62 1.99 0 3.613 1.625 3.613 3.62s-1.622 3.62-3.613 3.62a3.62 3.62 0 0 1-3.614-3.62m-1.422 0c0 2.78 2.26 5.044 5.036 5.044s5.036-2.262 5.036-5.043c0-2.78-2.26-5.044-5.036-5.044a5.046 5.046 0 0 0-5.036 5.044m-.357-4.958h-.21c-.635 0-1.247.2-1.758.595a.696.696 0 0 0-.674-.54.68.68 0 0 0-.68.684l.002 8.495a.687.687 0 0 0 1.372-.002v-5.224c0-1.74.16-2.444 1.648-2.63.14-.017.288-.014.29-.014.406-.015.696-.296.696-.675a.69.69 0 0 0-.69-.688m-13.182 4.127c0-.02.002-.04.003-.058a3.637 3.637 0 0 1 7.065.055H16.2zm8.473-.13c-.296-1.403-1.063-2.556-2.23-3.296a5.064 5.064 0 0 0-5.61.15 5.098 5.098 0 0 0-1.973 5.357 5.08 5.08 0 0 0 4.274 3.767c.608.074 1.2.04 1.81-.12a4.965 4.965 0 0 0 1.506-.644c.487-.313.894-.727 1.29-1.222.006-.01.014-.017.022-.027.274-.34.223-.826-.077-1.056-.254-.195-.68-.274-1.014.156-.072.104-.153.21-.24.315-.267.295-.598.58-.994.802-.506.27-1.08.423-1.69.427-1.998-.023-3.066-1.42-3.447-2.416a3.716 3.716 0 0 1-.153-.58l-.01-.105h7.17c.982-.022 1.51-.717 1.364-1.51z' /%3E %3C/svg%3E"
                     alt="Sign up with Xero"/>
                <span className="social-sign-up-text">Xero</span>
              </button>
              </div>
            </div>
            <Separator />
          </>
        }

          </div>
          <div>
            {this.props.product==='aip' && <img src={aider_welcome_image_g} width={500} />}
          </div>
        </div>

     {/*   <div className="disclaimer">
          By clicking Sign Up, you agree to our <Link href="https://www.aider.ai/terms" newWindow>Terms</Link> and
          our <Link href="https://www.aider.ai/privacy-policy" newWindow>Privacy Policy</Link>.
        </div>*/}
      </div>
    );
  }
};

SignUpForm.defaultProps = {
  signUpTypes: [SignUpType.EMAIL, SignUpType.WITH_PHONE, SignUpType.GOOGLE, SignUpType.FACEBOOK],
  readOnly: false,
  emailFormFields: {}
};

export default SignUpForm;
