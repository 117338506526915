import cookie from 'js-cookie';
import qs from 'qs';
import { makePostRequest } from './apiHelper';

const updateTokensExpiry = tokens => {
  tokens.expiresAt = Math.floor(Date.now() / 1000) + tokens.expires_in;
  return tokens;
};

export const initializeBackendTokens = async (apiLinks, idToken) => {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: `Basic ${btoa(process.env.REACT_APP_API_KEY)}`
  };

  const body = {
    grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
    subject_token: idToken,
    subject_token_type: 'urn:ietf:params:oauth:token-type:id_token'
  };

  const backendTokens = await makePostRequest(apiLinks.tokens.href, headers, qs.stringify(body)).then(updateTokensExpiry);
  cookie.set(process.env.REACT_APP_BACKEND_TOKENS_COOKIE_NAME, backendTokens, { domain: process.env.REACT_APP_COOKIE_DOMAIN });
};

export const refreshTokens = async apiLinks => {
  const backendTokens = cookie.getJSON(process.env.REACT_APP_BACKEND_TOKENS_COOKIE_NAME);
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: `Basic ${btoa(process.env.REACT_APP_API_KEY)}`
  };

  const body = {
    grant_type: 'refresh_token',
    refresh_token: backendTokens.refresh_token
  };

  const now = Math.floor(Date.now() / 1000);
  if ((now + process.env.BACKEND_TOKENS_EXPIRATION_OFFSET_SECONDS) >= backendTokens.expiresAt) {
    const refreshedTokens = await makePostRequest(apiLinks.tokens.href, headers, qs.stringify(body)).then(updateTokensExpiry);
    cookie.set(process.env.REACT_APP_BACKEND_TOKENS_COOKIE_NAME, refreshedTokens, { domain: process.env.REACT_APP_COOKIE_DOMAIN });
    return refreshedTokens;
  }

  return backendTokens;
};

export const getBackendTokens = () => cookie.getJSON(process.env.REACT_APP_BACKEND_TOKENS_COOKIE_NAME);

export const clearTokens = () => {
  cookie.remove(process.env.REACT_APP_BACKEND_TOKENS_COOKIE_NAME, { domain: process.env.REACT_APP_COOKIE_DOMAIN });
};
