import React from 'react';
import './separator.css';

const Separator = props => (
  <div className="separator">
    {props.children && <span className="separator-text">{props.children}</span>}
  </div>
);

export default Separator;
