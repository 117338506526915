import cookie from 'js-cookie';
import qs from 'qs';
import {removeURLParameter} from "./urlHelper";

const options = {
  domain: process.env.REACT_APP_COOKIE_DOMAIN,
  expires: parseInt(process.env.REACT_APP_COOKIE_EXPIRE_DAYS)
};

export const UrlParamNames = {
  utmSource: "utm_source",
  utmMedium: "utm_medium",
  utmCampaign: "utm_campaign",
  utmTerm: "utm_term",
  utmContent: "utm_content",
  cat: "cat",
  inviteToken: "inviteToken",
}

export const getAcquisitionData = () => {
  const query = qs.parse((window.location.search || '').replace('?', ''));
  const getParameterValue = name => {
    let value = query[name];
    if (value) {
      cookie.set(name, value, options);
    } else {
      value = cookie.get(name);
    }
    return value || null;
  };

  const result = {
    acquisition: {
      utmSource: getParameterValue(UrlParamNames.utmSource),
      utmMedium: getParameterValue(UrlParamNames.utmMedium),
      utmCampaign: getParameterValue(UrlParamNames.utmCampaign),
      utmTerm: getParameterValue(UrlParamNames.utmTerm),
      utmContent: getParameterValue(UrlParamNames.utmContent),
    },
    cat: getParameterValue(UrlParamNames.cat),
    inviteToken: getParameterValue(UrlParamNames.inviteToken)
  }

  if (query[UrlParamNames.inviteToken]) {
    const url = removeURLParameter(window.location.href, UrlParamNames.inviteToken);
    window.history.replaceState(null, document.title, url);
  }

  return result;
};

export const clearAllAcquisitionData = () => {
  Object.keys(UrlParamNames).forEach(name => {
    cookie.remove(UrlParamNames[name], options);
    console.log("cookie removed for ", UrlParamNames[name]);
  });
};
