import jwt from 'jsonwebtoken';
import { makeGetRequest, makeHeadRequest, makePostRequest } from './apiHelper';
import { getAcquisitionData } from './acquisitionHelper';
import { refreshTokens } from './backendTokenHelper';

export const acceptInvitation = async (apiLinks, inviteToken) => {
  const backendTokens = await refreshTokens(apiLinks);

  const headers = {
    Authorization: `Bearer ${backendTokens.access_token}`,
    'Content-Type': 'application/hal+json'
  };

  const body = JSON.stringify({
    _embedded: {
      invitationToken: inviteToken,
    }
  });

  return await makePostRequest(apiLinks.acceptInvitation.href, headers, body);
}

export const addUser = (authResult, apiLinks, isInvitation, partnerCustomerTokenInRedirectURL) => {
  if (authResult && authResult.user && partnerCustomerTokenInRedirectURL) {

    const { acquisition } = getAcquisitionData();

    const body = JSON.stringify({
      _embedded: {
        user: {
          providerUserId: authResult.user.uid,
          acquisition,
          partnerToken: partnerCustomerTokenInRedirectURL,
          providerId: 'xero.com',
        }
      }
    });

    const headers = {
      'Content-Type': 'application/hal+json'
    };

    return makePostRequest(apiLinks.users.href, headers, body);
    // return Promise.reject('Cannot create user from empty auth result.');

  } else if (authResult && authResult.user) {
    let givenName = null;
    let familyName = null;
    let gender = null;
    let providerId = null;
    let idToken = null;

    const authDomain = authResult.user.authDomain || authResult.user.w;

    if (authResult.user.providerData && authResult.user.providerData.length > 0) {
      if (authResult.user.providerData[0].providerId) {
        providerId = authResult.user.providerData[0].providerId;
      }
    }

    if (authResult.credential) {
      if (authResult.credential.providerId) {
        providerId = providerId || authResult.credential.providerId;
      }

      if (authResult.credential.idToken) {
        idToken = authResult.credential.idToken;
      }
    }

    if (authResult.additionalUserInfo) {
      if (authResult.additionalUserInfo.providerId) {
        providerId = providerId || authResult.additionalUserInfo.providerId;
      }

      if (authResult.additionalUserInfo.profile) {
        if (authResult.additionalUserInfo.profile.given_name) {
          givenName = authResult.additionalUserInfo.profile.given_name;
        }

        if (authResult.additionalUserInfo.profile.first_name) {
          givenName = authResult.additionalUserInfo.profile.first_name;
        }

        if (authResult.additionalUserInfo.profile.family_name) {
          familyName = authResult.additionalUserInfo.profile.family_name;
        }

        if (authResult.additionalUserInfo.profile.last_name) {
          familyName = authResult.additionalUserInfo.profile.last_name;
        }

        if (authResult.additionalUserInfo.profile.gender) {
          gender = authResult.additionalUserInfo.profile.gender;
        }
      }
    }

    let phoneNumber = authResult.user.phoneNumber;
    if (authResult.emailSignUp) {
      givenName = authResult.emailSignUp.firstName;
      familyName = authResult.emailSignUp.lastName;
      phoneNumber = authResult.emailSignUp.phoneNumber;
    }

    const displayName = authResult.user.displayName
      || [givenName, familyName].filter(name => name).join(' ').trim()
      || null;

    const { acquisition, inviteToken } = getAcquisitionData();

    const body = JSON.stringify({
      _embedded: {
        user: {
          providerUserId: authResult.user.uid,
          givenName,
          familyName,
          displayName,
          photoUrl: authResult.user.photoURL,
          email: authResult.user.email,
          emailVerified: authResult.user.emailVerified,
          phoneNumber,
          gender: gender,
          idToken: idToken,
          providerId: providerId,
          authDomain: authDomain,
          extras: authResult.additionalUserInfo || null,
          acquisition,
          invitationToken: isInvitation ? inviteToken : null
        }
      }
    });

    const headers = {
      'Content-Type': 'application/hal+json'
    };

    return makePostRequest(apiLinks.users.href, headers, body);
  } else {
    return Promise.reject('Cannot create user from empty auth result.');
  }
};

export const getUser = async (apiLinks, userId) => {
  const backendTokens = await refreshTokens(apiLinks);

  const headers = {
    Authorization: `Bearer ${backendTokens.access_token}`
  };

  const userResponse = await makeGetRequest(`${apiLinks.users.href}/${userId}`, headers);
  const userDetails = userResponse._embedded.userDetails;
  return userDetails;
}

export const getPartnerCustomerDetailsByToken = async apiLinks => {
  const { inviteToken } = getAcquisitionData();
  if (inviteToken) {
    const body = JSON.stringify({
      token: inviteToken
    });

    const headers = {
      'Content-Type': 'application/hal+json'
    };

    const partnerCustomerResponse = await makePostRequest(apiLinks.partnerCustomersByToken.href, headers, body);
    return partnerCustomerResponse._embedded;
  }

  return null;
}

export const getInvitationByToken = async apiLinks => {
  const { inviteToken } = getAcquisitionData();
  if (inviteToken) {
    const body = JSON.stringify({
      _embedded: {
        token: inviteToken
      }
    });

    const headers = {
      'Content-Type': 'application/hal+json'
    };

    const invitationsResponse = await makePostRequest(apiLinks.invitationsByToken.href, headers, body);
    return invitationsResponse._embedded;
  }

  return null;
}

/**
 * FIXME: Move this logic into the backend.
 */
export const getDetailsByToken = (apiLinks) => {
  const { inviteToken } = getAcquisitionData();

  if (inviteToken) {
    // Decode without validating to get claims
    const decoded = jwt.decode(inviteToken);
    if (decoded && decoded.claims && decoded.claims.tokenType) {
      return getInvitationByToken(apiLinks);
    }

    return getPartnerCustomerDetailsByToken(apiLinks);
  }

  return Promise.resolve();
};

export const checkUserExists = async (apiLinks, userId) => {
  return makeHeadRequest(`${apiLinks.users.href}/${userId}`)
    .then(() => true)
    .catch(() => false);
}
